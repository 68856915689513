<template>
  <div>
    <h2 style="text-align: center; margin: 0">
      カレンダー：{{ currentDate.year() }}年<span class="header-eto"
        >[{{ dateMan.westYear干支(currentDate) }}]
      </span>
      {{ currentDate.month() + 1 }}月<span class="header-eto"
        >[{{ dateMan.month干支(currentDate) }}]
      </span>
    </h2>
    <div id="buttons">
      <button @click="prevMonth">前の月</button>
      <button @click="nextMonth">次の月</button>
      <button @click="thisMonth">今月</button>
    </div>
    <div style="max-width: 900px; border: 5px solid gray">
      <div
        class="weekRow"
        style="
          display: flex;
          flex-direction: row;
          <!-- border-left: 5px solid gray; -->
          text-align: center;
        "
      >
        <div
          class="dayofweek"
          v-for="(dayOfWeek, index) in weekNames"
          :key="index"
          :class="getWeekClass(index)"
        >
          {{ dayOfWeek }}
        </div>
      </div>
      <div class="weekRow" v-for="(week, index) in calendars" :key="index">
        <div
          class="dayClass"
          v-for="(day, index) in week"
          :key="index"
          :class="[day.dayType, getWeekClass(index), day.monthType]"
        >
          <div
            style="
              display: flex;
              flex-direction: row;
              justify-content: space-between;
            "
          >
            <div class="day">{{ day.date }}</div>
            <div class="eto">{{ day.day干支 }}</div>
          </div>
          <img class="moon" :src="day.img" />
          <div class="sekki">{{ day.sekki }}</div>
          <div class="lunar-calendar">旧暦：{{ day.lunarDate }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import ShingetuList from "../assets/shingetu.json";
import SekkiList from "../assets/sekki.json";
import {
  LunarDate,
  LunarCalendarManager,
  DateManager,
} from "../modules/LunarCalendar.ts";

export default {
  data() {
    return {
      currentDate: moment(),
      today: moment(),
      weekNames: ["日", "月", "火", "水", "木", "金", "土"],
      lunarCalendar: new LunarCalendarManager(ShingetuList, SekkiList),
      dateMan: new DateManager(),
    };
  },
  methods: {
    getStartDate() {
      let date = moment(this.currentDate);
      date.startOf("month");
      const youbiNum = date.day();
      return date.subtract(youbiNum, "days");
    },
    getEndDate() {
      let date = moment(this.currentDate);
      date.endOf("month");
      const youbiNum = date.day();
      return date.add(6 - youbiNum, "days");
    },
    getCalendar() {
      let curDate = this.getStartDate();
      const endDate = this.getEndDate();
      const weekNumber = Math.ceil(endDate.diff(curDate, "days") / 7);

      let calendars = [];
      for (let week = 0; week < weekNumber; week++) {
        let weekRow = [];
        for (let day = 0; day < 7; day++) {
          let isToday = curDate.isSame(this.today, "day");
          let dayType = isToday ? "today" : "otherday";
          let monthType = curDate.isSame(this.currentDate, "month")
            ? "thismonth"
            : "othermonth";

          let date = curDate.format("YYYY-M-DD");
          let sekki = this.lunarCalendar.sekkiList[date];
          //console.log(date)
          if (sekki == null) {
            sekki = "";
          }
          const lunardate = this.getLunarDate(curDate);
          let lunaDateStr = "";
          let moonImgUrl = "";
          if (lunardate !== null) {
            lunardate.eto = this.dateMan.day干支(curDate);
            console.log(date);
            console.log(lunardate);
            const leapMonth = lunardate.isLeapMonth ? "閏" : "";

            lunaDateStr = `${leapMonth}${lunardate.month}/${lunardate.date}`;
            moonImgUrl = `/img/moon/moon${lunardate.date - 1}.svg`;
          }

          weekRow.push({
            date: curDate.get("date"),
            dayType: dayType,
            monthType: monthType,
            sekki: sekki,
            lunarDate: lunaDateStr,
            img: moonImgUrl,
            day干支: lunardate == null ? "" : lunardate.eto,
          });
          curDate.add(1, "days");
        }
        calendars.push(weekRow);
      }
      return calendars;
    },
    nextMonth() {
      this.currentDate = moment(this.currentDate).add(1, "month");
    },
    prevMonth() {
      this.currentDate = moment(this.currentDate).subtract(1, "month");
    },
    thisMonth() {
      this.currentDate = moment();
    },
    getWeekClass(index) {
      switch (index) {
        case 6:
          return "saturday";
        case 0:
          return "sunday";
        default:
          return "weekday";
      }
    },
    getLunarDate(date) {
      return this.lunarCalendar.lunarDate(date);
    },
  },
  // beforeCreate () {
  //   console.log(this.lunarCalendar.sekkiList);
  // },
  mounted() {
    console.log(this.getCalendar());
    console.log(DateManager.西暦十干表);
  },
  computed: {
    calendars() {
      return this.getCalendar();
    },
  },
};
</script>
<style>
div#buttons {
  display: flex;
  flex-flex-direction: row;
  max-width: 900px;
}

div#buttons button {
  background-color: lightgray;
  border-radius: 4px;
  width: 80px;
}

div#buttons button:last-of-type {
  margin-left: auto;
}

span.header-eto {
  font-size: 14px;
}
div.sunday {
  color: red;
}
div.saturday {
  color: blue;
}
div.weekRow {
  display: flex;
  border-bottom: 3px solid gray;
}
div.weekRow:last-of-type {
  border-bottom: none;
}
div.dayClass {
  background-color: rgb(232, 232, 248);
  flex: 1;
  min-height: 70px;
  border-left: none;
  border-right: 3px solid gray;
  /* border-bottom: 3px solid gray; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
div.dayClass:last-of-type {
  border-right: none;
}
div.othermonth {
  background-color: lightgray;
  color: darkgray;
}
div.today {
  background-color: rgb(136, 231, 255);
  font-weight: 2;
}
div.dayofweek {
  background-color: rgb(232, 232, 248);
  flex: 1;
  border-right: 3px solid gray;
  text-align: center;
}
div.dayofweek:last-child {
  border-right: none;
}
div.eto {
  padding-left: 10px;
  font-size: 10px;
}
img.moon {
  width: 15px;
  height: 15px;
  padding-left: 10px;
  align-self: flex-end;
}
div.sekki {
  font-size: 10px;
  min-height: 12px;
}
div.lunar-calendar {
  font-size: 9px;
}
</style>
