import moment from "moment";

class ChukiKind {
  name: string;
  month: number;

  constructor(name: string, month: number) {
    this.name = name;
    this.month = month;
  }
}

class Chuki {
  chuki: ChukiKind;
  date: moment.Moment;

  constructor(chuki: ChukiKind, date: moment.Moment) {
    this.chuki = chuki;
    this.date = date;
  }
}

const 中気set = new Map<string, ChukiKind>([
  ["雨水", new ChukiKind("雨水", 1)],
  ["春分", new ChukiKind("春分", 2)],
  ["穀雨", new ChukiKind("穀雨", 3)],
  ["小満", new ChukiKind("小満", 4)],
  ["夏至", new ChukiKind("夏至", 5)],
  ["大暑", new ChukiKind("大暑", 6)],
  ["処暑", new ChukiKind("処暑", 7)],
  ["秋分", new ChukiKind("秋分", 8)],
  ["霜降", new ChukiKind("霜降", 9)],
  ["小雪", new ChukiKind("小雪", 10)],
  ["冬至", new ChukiKind("冬至", 11)],
  ["大寒", new ChukiKind("大寒", 12)],
]);

export class LunarMonth {
  year: number;
  month: number;
  firstDay: moment.Moment;
  lastDay: moment.Moment;
  chuki: Chuki | null;

  constructor(firstDay: moment.Moment) {
    this.year = -1;
    this.month = -1;
    this.firstDay = firstDay;
    this.lastDay = firstDay;
    this.chuki = null;
  }

  get is閏月(): boolean {
    return this.chuki == null;
  }

  hasDate(date: moment.Moment): boolean {
    return date.isBetween(this.firstDay, this.lastDay, undefined, "[]");
  }

  // 対応する旧暦のこの月の日にちを返す。
  // dateは、この月に含まれていることが前提
  lunarDate(date: moment.Moment): number {
    return date.diff(this.firstDay, "days") + 1;
  }
}

export class LunarDate {
  origDate: moment.Moment;

  date: number;
  month: number;
  eto: string;
  isLeapMonth: boolean;

  constructor(origDate: moment.Moment, month: LunarMonth) {
    this.origDate = origDate;
    this.month = month.month;
    this.date = month.lunarDate(origDate);
    this.isLeapMonth = month.is閏月;
    this.eto = ""; //DateManager.day干支(origDate);
  }
}

export class LunarCalendarManager {
  sakuList: moment.Moment[];
  monthList: LunarMonth[];
  sekkiList: Record<string, string>;

  constructor(shingetuList: string[], sekkiList: Record<string, string>) {
    this.sakuList = shingetuList.map(function (date: string): moment.Moment {
      return moment(date, "YYYY-M-DD");
    });
    this.monthList = this.makeMonths(sekkiList);
    this.sekkiList = sekkiList;
  }

  private makeMonths(sekkiList: Record<string, string>): LunarMonth[] {
    const monthList: LunarMonth[] = [];

    // 月のリストを作成
    let lastMonth: LunarMonth | null = null;
    for (const sakuDate of this.sakuList) {
      const firstday = moment(sakuDate, "YYYY-M-DD");
      if (lastMonth !== null) {
        const lastDay = firstday.clone();
        lastMonth.lastDay = lastDay.add(-1, "days");
      }
      const newLunarMonth = new LunarMonth(firstday);
      monthList.push(newLunarMonth);
      lastMonth = newLunarMonth;
    }

    if (lastMonth === null) {
      return monthList;
    }

    // 最後の旧暦の月末はわからないので、とりあえず西暦の月末を設定する
    lastMonth.lastDay = lastMonth.firstDay.clone().endOf("month");

    // 中気を使って、旧暦の月を決定
    // 24節気に中気が含まれており、中気に対応する数字が旧暦の月になる
    Object.entries(sekkiList).forEach(([date, sekki]) => {
      const sekkiStr: string = sekki as string;
      const chukiKind = 中気set.get(sekkiStr);
      const theDate = moment(date, "YYYY-M-DD");
      if (chukiKind !== undefined) {
        // 中気か？
        for (const month of monthList) {
          if (month.hasDate(theDate)) {
            month.month = chukiKind.month;
            month.chuki = new Chuki(chukiKind, moment(date, "YYYY-M-DD"));
            break;
          }
        }
      }
    });

    // うるう月を設定
    lastMonth = null;
    for (const month of monthList) {
      if (month.is閏月 && lastMonth !== null) {
        month.month = lastMonth.month;
      }
      lastMonth = month;
    }

    return monthList;
  }

  lunarDate(date: moment.Moment): LunarDate | null {
    let theMonth: LunarMonth | null = null;
    for (const month of this.monthList) {
      if (month.hasDate(date)) {
        theMonth = month;
        break;
      }
    }

    if (theMonth !== null) {
      return new LunarDate(date, theMonth);
    } else {
      return null;
    }
  }
}

const weekArray = ["日", "月", "火", "水", "木", "金", "土"];
const daysPerWeek = 7;
const epock = moment("1980-02-17", "YYYY-MM-DD"); // 適当な「庚申」の日

export class DateManager {
  static 西暦十干表 = [
    "庚",
    "辛",
    "壬",
    "癸",
    "甲",
    "乙",
    "丙",
    "丁",
    "戊",
    "己",
  ];
  static 西暦十二支 = [
    "申",
    "酉",
    "戌",
    "亥",
    "子",
    "丑",
    "寅",
    "卯",
    "辰",
    "巳",
    "午",
    "未",
  ];

  /**
     1980-02-17(庚申)からの差(n日)を取得
     - parameter date: 日付
     - returns: 算出後の日付
     */
  getDaysSinceEpoc(date: moment.Moment): number {
    return date.diff(epock, "days"); // n日
  }

  westYear干支(date: moment.Moment): string {
    const year = date.year();
    return this.get十干(year) + this.get十二支(year);
  }

  day干支(date: moment.Moment): string {
    const days = this.getDaysSinceEpoc(date);
    return this.get十干(days) + this.get十二支(days);
  }

  month干支(date: moment.Moment): string {
    const year = date.year();
    const month = date.month();
    const count = (year % 5) * 12 + month + 7;
    return this.get十干(count) + this.get十二支(month + 5);
  }

  get十干(count: number): string {
    const i = count % 10;
    return DateManager.西暦十干表[i];
  }

  get十二支(count: number): string {
    const i = count % 12;
    return DateManager.西暦十二支[i];
  }
}
